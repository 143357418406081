



























































import {Component, Vue} from "vue-property-decorator";
import BranchDto, {CreateBranchPayload, LocationType} from "@/components/profile/branches/BranchDtos";
import BranchEditor from "@/components/profile/branches/BranchEditor.vue";
import CompanyService from "@/services/CompanyService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import SelectOption from "@/components/common/SelectOption";
import {prettyEnum} from "@/utils/StringUtils";
import {ifValid} from "@/utils/ComponentUtils";
import AddressDTO from "@/dto/AddressDTO";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");

@Component({
	components: {PortalTextarea, PortalInput, PortalAddress, PortalDate, PortalCheckbox, PortalSelect, BranchEditor}
})
export default class Headquarter extends Vue {

	private message = "";

	private successful = false;

	private headquarter: BranchDto = new BranchDto();

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted() {
		this.loadHeadQuarter()
	}

	private loadHeadQuarter(): void {
		this.startLoading();
		CompanyService.getHeadQuarter().then(
			res => {
				if (res.data) {
					res.data.legalAddress = !res.data.legalAddress ? new AddressDTO() : res.data.legalAddress;
					res.data.mailingAddress = !res.data.mailingAddress ? new AddressDTO(): res.data.mailingAddress;
					res.data.actualAddress = !res.data.actualAddress ? new AddressDTO(): res.data.actualAddress;
					res.data.registrationAgentAddress = !res.data.registrationAgentAddress ? new AddressDTO() : res.data.registrationAgentAddress;
					res.data.headQuarter = true
					this.headquarter = res.data
				}
				this.stopLoading();
			},
			err => this.processError(err)
		)
	}

	private buildOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(prettyEnum(LocationType.DOMESTIC)).value(LocationType.DOMESTIC).build(),
			SelectOption.builder().title(prettyEnum(LocationType.FOREIGN)).value(LocationType.FOREIGN).build()
		]
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private update(): void {
		this.message = "";
		ifValid(this, () => {
			this.startLoading();
			CompanyService.updateBranch(this.headquarter).then(
				() => {
					this.successful = true;
					this.message = "Data successfully updated";
					this.stopLoading()
				},
				err => this.processError(err)
			)
		})
	}


	private save(): void {
		this.message = "";
		ifValid(this, () => {
			this.startLoading();
			let req = new CreateBranchPayload();
			req.locationType = this.headquarter.locationType;
			req.companyId = Workspaces.getCurrent.id;
			req.title = this.headquarter.title;
			req.description = this.headquarter.description;
			req.legalAddress = this.headquarter.legalAddress
			req.actualAddress = this.headquarter.actualAddress
			req.mailingAddress = this.headquarter.mailingAddress
			req.registrationAgentAddress = this.headquarter.registrationAgentAddress
			req.registrationDate = this.headquarter.registrationDate
			req.headQuarter = true
			CompanyService.createBranch(req).then(
				() => {
					this.successful = true;
					this.message = "Data successfully updated";
					this.stopLoading()
				},
				err => this.processError(err)
			)
		})
	}
}

